import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import logo from './logo.png';
import './App.css';

import axios from 'axios';
import { Alert, Box, TextField } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';

function App() {
  const [businessOrig, setBusinessOrig] = React.useState([]);
  const [business, setBusiness] = React.useState([]);
  const [search, setSearch] = React.useState();

  React.useEffect(() => {
    axios.get("https://api.upiterpoints.mx/pbusiness").then((response) => {
      setBusiness(response.data.businesses);
      setBusinessOrig(response.data.businesses);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  const onItemPressed = ({ name }) => {
    const url = `https://wa.me/5215564431311?text=Hola%20bot%20upiter,%20quiero%20ganar%20puntos%20en%20${name}`;
    window.open(url, '_blank');
  };

  React.useEffect(() => {
    if (search) {
      setBusiness((business) => business.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())));
    } else {
      setBusiness(businessOrig);
    }
  }, [search, businessOrig]);

  return (
    <div className="App">
      <div className="App-container">
        <img
          alt="Upiter Points"
          src={logo}
          style={{ width: '200px', height: '200px' }}
        />
        <Alert severity="info" sx={{ width: "80%", marginTop: 2 }}>Al elegir uno, sólo envía el mensaje al bot, para seleccionar el negocio.</Alert>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: "center", width: "100%", paddingLeft: 2 }}>
          <SearchOutlined sx={{ color: '#814590', mr: 1, my: 0.5 }} />
          <TextField id="input-with-sx" label="Buscar" variant="standard" sx={{ width: "76%" }} onChange={(e) => setSearch(e.target.value)} fullWidth />
        </Box>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {
            business?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ListItem alignItems="flex-start" onClick={() => onItemPressed(item)}>
                    <ListItemAvatar>
                      <Avatar alt={item.name} src={item.logoUrl} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.name}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {item.instagram_handler || ''}
                          </Typography>
                         {" "} {item.description}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>)
            })
          }
        </List>
        <div className="App-footer">
          <p>contacto@upiterpoints.mx</p>
          <p><a href="https://upiterpoints.mx" className="App-footer-link" >upiterpoints.mx</a></p>
        </div>
      </div>
    </div>
  );
}

export default App;
